import * as THREE from 'three'
import CANNON, { World } from 'cannon'
import GUI from 'lil-gui'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'


/**
 * @ Base
 */
// * Canvas
const canvas = document.querySelector('canvas.webgl')

// * GUI
const gui = new GUI()

// * Sizes
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () => {
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

// * Scene
const scene = new THREE.Scene()
scene.background = new THREE.Color( 0xffffff );

// * Loaders
const textureLoader = new THREE.TextureLoader()
const fontLoader = new FontLoader();

/** 
 * @ Camera
 */
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 10)
camera.position.set(3, 3, 3)
camera.lookAt(0, 0, 0)
scene.add(camera)

// * Orbit Controls
const control = new OrbitControls(camera, canvas)
control.enableDamping = true

/**
 * @ Lights
 */
// * Ambient Light 
const ambientLight = new THREE.AmbientLight('#ffffff', 1)
scene.add(ambientLight)

/**
 * @ Objects
 */
// * Floor
const floorColorTexture = textureLoader.load('./textures/floor/laminate_floor_diff_1k.jpg')
const floorARMTexture = textureLoader.load('./textures/floor/laminate_floor_arm_1k.jpg')
const floorDispTexture = textureLoader.load('./textures/floor/laminate_floor_disp_1k.jpg')
const floorNormalTexture = textureLoader.load('./textures/floor/laminate_floor_nor_gl_1k.jpg')

const floor = new THREE.Mesh(
    new THREE.PlaneGeometry(20, 20, 100, 100),
    new THREE.MeshStandardMaterial({
        map: floorColorTexture,
        aoMap: floorARMTexture,
        roughnessMap: floorARMTexture,
        metalnessMap: floorARMTexture,
        displacementMap: floorDispTexture,
        displacementScale: 0.3,
        normalMap: floorNormalTexture
    })
)
floorColorTexture.colorSpace = THREE.SRGBColorSpace
floor.rotation.x = -Math.PI * 0.5
// scene.add(floor)

/**
 * @ Physics
 */
const world = new CANNON.World()
world.gravity.set(0, -9.82, 0)

// * Floor
const floorBody = new CANNON.Body({
    mass: 0,
    position: new CANNON.Vec3(0, 0, 0),
    shape: new CANNON.Plane()
})
floorBody.quaternion.setFromAxisAngle(new CANNON.Vec3(- 1, 0, 0), Math.PI * 0.5)
world.addBody(floorBody)

/**
 * @ Text Falling Letters
 */
const matcapTexture = textureLoader.load('/textures/matcaps/9.png')
matcapTexture.colorSpace = THREE.SRGBColorSpace

const letters = [] // To store the text meshes
const letterBodies = [] // To store the physics bodies

const ubuntuMono = '/fonts/Ubuntu Mono_Regular.json'
const kong = 'fonts/Kongtext_Regular.json'
fontLoader.load(kong, (font) => {
    const textMaterial = new THREE.MeshMatcapMaterial({ matcap: matcapTexture })
    
    // * Split the name into individual letters
    const name = "sue young shim".split("")
    
    name.forEach((letter, index) => {
        // Create geometry for each letter
        const textGeometry = new TextGeometry(letter, {
            font: font,
            size: 0.5,
            depth: 0.2,
            curveSegments: 8,
            bevelEnabled: true,
            bevelThickness: 0.03,
            bevelSize: 0.02,
            bevelOffset: 0,
            bevelSegments: 3
        })

        // Create a mesh for the letter
        const text = new THREE.Mesh(textGeometry, textMaterial)
        text.position.set(index * 0.6 - 4, 5 + index * 1.5, 0) // Staggered initial positions
        scene.add(text)
        letters.push(text)

        // console.log(text)

        // Create physics body for the letter
        const boxShape = new CANNON.Box(new CANNON.Vec3(0.3, 0.1, 0.1)) // Adjust size to fit the letter
        const boxBody = new CANNON.Body({
            mass: 1,
            position: new CANNON.Vec3(index * 0.6 - 3, 5 + index * 1.5, 0), // Corresponding position in physics
            shape: boxShape,
            material: new CANNON.Material({ restitution: 0.8})
        })
        world.addBody(boxBody)
        letterBodies.push(boxBody)
    })
})

// Position letters in readable form first
letterBodies.forEach((body, index) => {
    body.position.set(index * 0.6 - 3, 0.5, 0); // Positioned neatly in the air
    body.velocity.set(0, 0, 0); // No velocity initially
});

// On click or after delay, start the drop
setTimeout(() => {
    letterBodies.forEach((body) => {
        body.velocity.set(0, -5, 0); // Start falling down with gravity
    });
}, 3000); // 3-second delay before dropping

/**
 * @ Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

renderer.render(scene, camera)


/**
 * @ Animate
 */
const clock = new THREE.Clock()
let oldElapsedTime = 0
const tick = () => {
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - oldElapsedTime
    oldElapsedTime = elapsedTime

    // Update physics world
    world.step(1/60, deltaTime, 3)

    // Update each letter's position based on its corresponding physics body
    letters.forEach((letter, index) => {
        letter.position.copy(letterBodies[index].position)
        letter.quaternion.copy(letterBodies[index].quaternion)
    })

    // Render the scene
    renderer.render(scene, camera)

    control.update()

    window.requestAnimationFrame(tick)
}

tick()
